/* You can add global styles to this file, and also import other style files */
html {
  background: #1b1b1b;
}
body, html{
  margin:0;
  height: 100%;
}

.rounded-container{
  border: 1px solid var(--orange-main1);
  border-radius: 25px;
  box-shadow: 4px 5px var(--orange-main1);
  box-shadow: 0 4px 8px 0 var(--orange-main1), 0 6px 20px 0 var(--orange-main1), 0 6px 14px 0 var(--orange-main1);
}

:root{
  //Interactive
  --monkey-hover: "../../../assets/logos/toad-1.jpeg";

  // Colors 
  --purple-main1: rgb(184,75,114); 
  --orange-main1: #FFB03A;

  // Font
  --text-creepster: "Creepster";
  --text-body-gothic: "Century Gothic", CenturyGothic, AppleGothic;
  --text-body-georgia: Georgia, Times, "Times New Roman";
}

.standard-header-orange{
    font-family: var(--text-creepster);
    color: #FFC50C;
    color: linear-gradient(purple, green);
    font-size: 4em;
    text-shadow: 8px 8px 8px black, 9px 8px 8px black, 8px 8px 8px black, 8px 8px 8px black;
    text-align: center;
    margin: 0;
}
.standard-header-purple{
    color: var(--purple-main1);
    text-shadow: 1px 1px gray;
    font-size: 4em;
    text-shadow: 8px 8px 8px black, 9px 8px 8px black, 8px 8px 8px black, 8px 8px 8px black;
    font-family: var(--text-creepster);
    text-align: center;
    margin: 0;
}
// New Music Banner
.blink-time {
    animation: blinker 4s linear infinite;
  }
  
  @keyframes blinker {
    10%{
       background: var(--purple-main);
    }
    25%{
        background: rgb(14, 90, 14);
    }
    50% {
        background: #FFC50C;
    }
    70%{
        background: var(--purple-main);
    }
    90%{
        background: rgb(237, 22, 22);
    }
  }
.new-music-text{
    text-decoration: none;
    color: var(--purple-main1);
    font-size: 25px;
    font-weight: 700;
    text-shadow: 2px 2px 2px grey;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: black;
    transition: transform 1s;
}
// .corner-ribbon-wrapper{
//     position: relative;
//     overflow: hidden;
// }
// .corner-ribbon {
//     width: 250px;
//     background: #FFC50C;
//     position: absolute;
//     top: 100px;
//     left: -50px;
//     text-align: center;
//     line-height: 50px;
//     letter-spacing: 1px;
//     color: #f0f0f0;
//     transform: rotate(-45deg);
//     -webkit-transform: rotate(-45deg);
//     overflow: hidden;
//   }
//   .corner-ribbon.shadow {
//     box-shadow: 0 0 3px rgba(0, 0, 0, .3);
//   }
//   /* Different positions */
  
//   .corner-ribbon.top-right {
//     /* top: 18px; */
//       top:100px;
//     right: -70px;
//     left: auto;
//     transform: rotate(45deg);
//     -webkit-transform: rotate(46deg);
//     overflow: hidden;
//   }
